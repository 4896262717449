.products-blurb {
  font-size: 1.5rem;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: auto;
  padding: 20px;
  border-radius: 2px;
  background-color: #003366;
}

.card {
  perspective: 1000px;
  margin: 2%;
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-inner {
  position: relative;
  text-align: center;
  width: 200px;
  height: 100px;
  max-width: 100%;
  transition: transform 0.75s ease-in-out;
  transform-origin: 50% 50%;
  transform-style: preserve-3d;
}

.card-front {
  cursor: pointer;
  color: #003366;
  background-color: #F9FAFB;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, .1);
}

.product-logo {
  width: 250px;
  height: 100px;
  border-radius: 2px;
}

.card-front,
.card-back {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  border-radius: 2px;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden;
}

.card-back {
  display: grid;
  font-family: 'Noto Sans', sans-serif;
  transform: rotateY(180deg);
  background-color: #F9FAFB;
}

.ext-link {
  font-size: 25px;
  color: #003366;
}

.ext-link:hover {
  cursor: pointer;
  color: #F68D0B;
}
