$blue: #003366;
$red: #CC0000;
$lightGray: #F9FAFB;
$gray: #E5E5E5;
$gold: #F68D0B;

html,
body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
  margin: 0px;
  padding: 0px;
  border-radius: 2px;
}

h1 {
  display: flex;
  justify-content: center;
  font-size: 3rem;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.2rem;
}

h2 {
  display: flex;
  justify-content: center;
  padding: 20px;
  padding-bottom: 40px;
  width: 70%;
  margin: auto;
  text-align: center;
  line-height: 2.5rem;
  font-size: 1.2rem;
  font-weight: lighter;
  letter-spacing: 0.2rem;
  color: #003366;
  font-family: 'Noto Sans', sans-serif;
}

.company-title {
  text-align: center;
  font-size: 3rem;
}

.contact-email {
  text-decoration: none;
  color: #003366;
}

.contact-email:hover {
  cursor: pointer;
  color: #F68D0B;
}

@media screen and (prefers-reduced-motion: reduce),
(update: slow) {
  * {
    animation-duration: 0.001ms !important;
    transition-duration: 0.001ms !important;
  }
}

@media only screen and (min-device-width : 20em) and (max-device-width : 50em) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {

  html,
  body {
    overflow-x: hidden;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}
