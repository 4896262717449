.serv-main {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: auto;
}

.serviceCycle {
  margin: auto;
  text-align: center;
  width: 50%;
}

.serv-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  text-align: center;
  margin: auto;
  transform: translateY(20vh);
  background-image: linear-gradient(#E5E5E5, white);
  z-index: -10;
}

.serv-card-container.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.card-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 15px;
}

.serv-card {
  margin: 20px;
  padding: 10px;
  width: 320px;
  line-height: 2rem;
  z-index: 10;
  border-radius: 2px;
  background-color: #003366;
}

.serv-name {
  margin-bottom: 42px;
  font-size: 1.1rem;
  letter-spacing: 0.2rem;
  color: #F9FAFB;
}

.serv-name-2 {
  margin-bottom: 10px;
  font-size: 1.1rem;
  letter-spacing: 0.2rem;
  color: #F9FAFB;
}

.serv-img {
  width: 300px;
  height: 300px;
  border-radius: 2px;
}

.serv-p {
  text-align: justify;
  padding: 5px;
  hyphens: auto;
  font-family: 'Noto Sans', sans-serif;
  color: #F9FAFB;
}

@media only screen and (min-device-width : 20em) and (max-device-width : 50em) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .circleArrow {
    width: 80%;
  }

  .serv-card {
    margin: auto;
  }
}
