.contact-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 5%;
}

.contact-logo {
  position: relative;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-bottom: 10px;
  width: 80px;
}

.contact-info {
  margin: 0 auto;
  text-align: center;
  max-width: 300px;
  line-height: 1.5rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

.contact-info-title {
  font-weight: bold;
  color: #CC0000;
}

.contact-email {
  display: flex;
  justify-content: center;
}

@media only screen and (min-device-width : 20em) and (max-device-width : 50em) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .contact-logo {
    padding: 10px 0 10px 0;
  }

  .contact-container {
    grid-template-columns: 1fr;
    margin-bottom: 5%;
  }
}
