.navbar {
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  overflow: hidden;
  width: 100%;
  top: 0;
  left: 0;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
  z-index: 100;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.logo-icon {
  width: 4rem;
}

.logo-icon:hover {
  cursor: pointer;
}

.link {
  font-size: 1rem;
  text-transform: uppercase;
  text-decoration: none;
  padding: 1rem;
  color: #003366;
  transition: 0.45s ease-in-out;
}

.link::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 3px;
  top: 100%;
  left: 0;
  background: #F68D0B;
  transition: transform 0.5s;
  transform: scaleX(0);
}

.link:hover {
  cursor: pointer;
  color: #F68D0B;
  transform: scale(1.0);
}

.link:hover::after {
  transform: scaleX(0.7);
}

@media only screen
and (min-device-width : 20em) 
and (max-device-width : 50em)
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: portrait) {
  .navbar {
    transition: top 0.3s;
  }
  .logo-icon {
    display: none;
  }
}
