.logo-title {
  display: flex;
  flex-direction: column;
  margin-top: 10%;
}

.home-logo {
  width: 125px;
  margin: auto;
}

.home-company-title {
  margin: auto;
  padding: 40px;
  width: 70%;
  font-family: 'Red Rose', 'Open Sans', sans-serif;
  text-align: center;
  font-size: 4rem;
  font-weight: bolder;
}

.home-p {
  width: 50%;
  font-family: 'Noto Sans', sans-serif;
  letter-spacing: 0.3rem;
  line-height: 2rem;
  font-weight: bolder;
  color: #003366;
}

.home-contact-info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 50px;
  padding: 20px;
  background-color: #F9FAFB;
}

@media only screen and (min-device-width : 20em) and (max-device-width : 50em) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .home-logo {
    margin-top: 30%;
  }

  .home-contact-info {
    grid-template-columns: 1fr;
    margin-top: 50px;
  }
}
