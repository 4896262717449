.about-p {
  margin: auto;
  width: 80%;
  line-height: 2rem;
}

.profile-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 20px;
  padding: 20px;
  transform: translateY(20vh);
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  background-color: #003366;
  z-index: -10;
}

.profile-container.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.profile-card {
  padding: 20px;
  width: 300px;
  text-align: center;
  font-family: 'Noto Sans', sans-serif;
}

.profile-card-inside {
  padding: 10px;
  border-radius: 2px;
  background-color: white;
}

.profile-img {
  width: 150px;
  border-radius: 50%;
}
